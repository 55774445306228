<template>
    <div>
        <h1 id="title-source">Детали типа лицензий #{{ licenseTypeId }}</h1>

        <RequestWrapper
            v-bind:data="licenseType"
            v-bind:errorText="licenseTypeErrorText"
            v-bind:errorCode="licenseTypeErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Идентификатор">
                        {{ licenseType.licenseTypeId }}
                    </ItemDetail>
                    <ItemDetail name="Название">
                        {{ licenseType.name }}
                    </ItemDetail>
                    <ItemDetail name="Срок действия (дней)">
                        {{ licenseType.expirationPeriodDays || 'Не ограничен' }}
                    </ItemDetail>
                    <ItemDetail name="Название в регистрационном коде">
                        {{ licenseType.regCodeName || 'Не установлено' }}
                    </ItemDetail>
                    <ItemDetail name="Продление лицензии">
                        {{ licenseType.isProlongation ? 'Да' : 'Нет' }}
                    </ItemDetail>
                    <ItemDetail name="SubLicense-продление лицензии">
                        {{ licenseType.isSubLicense ? 'Да' : 'Нет' }}
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-support">
                    <Button
                        text="Редактировать"
                        v-on:click="$router.push(`/licensing/license-types/${licenseTypeId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/license-types/${licenseTypeId}/delete`)" />
                </ButtonsBlock>

                <h2>Поиск</h2>
                <ul>
                    <li>
                        <router-link v-bind:to="`/licensing/orders?mode=search&licenseTypeId=${licenseTypeId}`">
                            Ордеры на продукты с лицензиями этого типа
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/licenses?mode=search&licenseTypeId=${licenseTypeId}`">
                            Выписанные лицензии этого типа
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            v-bind:to="`/licensing/product-mappings?mode=search&licenseTypeId=${licenseTypeId}`">
                            Маппинги продуктов, использующие этот тип лицензии
                        </router-link>
                    </li>
                    <li class="access-level-admin">
                        <router-link
                            v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/license-types/${licenseTypeId}`">
                            Записи аудита на этот тип лицензий
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'LicenseTypeViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            licenseTypeId: this.$route.params.id,
            licenseType: undefined,
            licenseTypeErrorText: undefined,
            licenseTypeErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/license-types/${this.licenseTypeId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.licenseType = data))
                        break
                    default:
                        this.licenseTypeErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.licenseTypeErrorText = error))
    },
}
</script>