<template>
    <div>
        <h1 id="title-source">История версий</h1>

        <div class="version">
            <span class="version-number">1.13.1</span>
            <span class="version-date">2024.12.23</span>
        </div>
        <div class="changes-section">Дополнение интеграции с YooKassa</div>
        <ul>
            <li>
                Сервис приёма IPN получил теперь не только принимает уведомления от ЮКассы, но и имеет API для
                работы со специальными временными ордерами.
            </li>
            <li>
                Поле "Идентификатор продукта у регистратора" в маппинге продуктов стало опциональным, так как
                для ЮКассы оно должно быть пустым.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.13.0</span>
            <span class="version-date">2024.12.18</span>
        </div>
        <div class="changes-section">Интеграция с YooKassa и ссылки на покупку продления лицензий у продуктов</div>
        <ul>
            <li>
                Сервис приёма IPN получил интеграцию с YooKassa. Поддерживается обработка уведомлений об успешном
                платеже и об успешном рефанде.
            </li>
            <li>
                У продуктов в сервисе лицензирования появилось поле "Шаблон ссылки на покупку продления". Эта ссылка
                теперь используется в письмах об истечении лицензий вместо шаблона ссылки на покупку из настроек
                регистратора.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.12.2</span>
            <span class="version-date">2024.11.20</span>
        </div>
        <div class="changes-section">Боты Google HSTS Preload и доступ к HTTP-заголовкам в правилах Karma Gate</div>
        <ul>
            <li>
                В Karma.GateApp добавлена возможность использовать HTTP-заголовки запросов в выражениях условий правил.
            </li>
            <li>
                В production-настройки добавлено разрешающее правило для запросов с IP-адресов, помеченных как Google
                Other, с User-Agent = hstspreload-bot.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.12.1</span>
            <span class="version-date">2024.06.12</span>
        </div>
        <div class="changes-section">Тестирование Karma Gate с помощью подмены IP-адреса</div>
        <ul>
            <li>
                В Karma.GateApp добавлена поддержка намеренной подмены IP-адреса через заголовок X-Forwarded-For. С
                помощью этого заголовка можно тестировать как работает гейт для разных IP-адресов. Для использования
                заголовка требуется внести свой IP-адрес в список KnownProxies в конфигурации сервиса.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.12.0</span>
            <span class="version-date">2024.03.13</span>
        </div>
        <div class="changes-section">Улучшения и исправления в Karma Gate</div>
        <ul>
            <li>Оптимизировано потребление памяти, устранена утечка.</li>
            <li>Упрощена обработка многих запросов, что снизило потребление процессора.</li>
            <li>Переделано кеширование запросов - оно полностью делегировано библиотеке от Microsoft.</li>
            <li>
                Кеширование теперь форсируется на стороне браузера для тех ответов, в которых не указано явно, что
                кеширование запрещено.
            </li>
            <li>Длительность жизни кешированных ответов значительно увеличена.</li>
            <li>
                Переделано выполнение запросов на целевые сайты - оно полностью делегировано прокси-библиотеке от
                Microsoft.
            </li>
            <li>Ограничение параллелизации обработки запросов убрано.</li>
            <li>Заменена библиотека, отвечающая за вычисление выражений, что упростило и сами вычисления.</li>
        </ul>
        <div class="changes-section">Общие улучшения в инфраструктурных сервисах</div>
        <ul>
            <li>Сервисы переведены на dotnet 8.0.</li>
            <li>Оптимизирована работа с логированием.</li>
            <li>
                В метрики добавлены показатели dotnet runtime, что позволяет в том числе мониторить работу сборщика
                мусора.
            </li>
        </ul>
        <div class="changes-section">Улучшения в BackOffice</div>
        <ul>
            <li>Исправлена порча вёрстки страницы информации о веб-сессии при длинных адресах страниц.</li>
            <li>Исправлен текст названия генератора лицензий на странице инструментов лицензирования.</li>
        </ul>
        <div class="changes-section">Улучшения в приёмнике телеметрии</div>
        <ul>
            <li>Устранена утечка памяти - время жизни сессий в кеше ограничено.</li>
            <li>Убраны излишние записи веб-сессий в кеши.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.11.1</span>
            <span class="version-date">2023.12.31</span>
        </div>
        <div class="changes-section">Улучшения и исправления в Karma Gate</div>
        <ul>
            <li>Исправлена ошибка в работе отказоустойчивости при недоступности сервиса репутации.</li>
            <li>Улучшена заплатка от уязвимости с передачей HTTP-адресов в пути запроса.</li>
            <li>
                Переделан способ настройки HTTPS-редиректов, они поставлены ниже по приоритету чем правила редиректов
                при кривых путях, содержащих HTTP-адреса.
            </li>
            <li>
                Добавлены правила редиректов, отрезающие query-строку, если она начинается с аргументов gclid,
                utm_content или hash.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.11.0</span>
            <span class="version-date">2023.12.17</span>
        </div>
        <div class="changes-section">Контроль фоновых служб</div>
        <ul>
            <li>
                Инфраструктурные бэкенд-сервисы теперь предоставляют через API возможность работы со встроенными
                фоновыми службами. Можно читать их текущее состояние, последние результаты работы, а так же форсировать
                выполнение.
            </li>
            <li>
                Ключевые параметры фоновых служб публикуются в метриках, чтобы можно было настраивать уведомления о
                проблемах в их функционировании.
            </li>
            <li>
                Фоновые активности, которые ранее не были основаны на общей базе, в которую внедрены эти возможности,
                были приведены к общему знаменателю с остальными, таким образом они тоже теперь контролируемы извне.
            </li>
            <li>
                В бэк-офисе появилась соответствующая
                <router-link to="/misc/maintainers">страница для работы с фоновыми службами</router-link>.
            </li>
        </ul>
        <div class="changes-section">HTTP-метрики во все бэкенд-сервисы</div>
        <ul>
            <li>
                Инфраструктурные бэкенд-сервисы теперь все предоставляют метрики HTTP-запросов, чтобы их можно было
                отображать в Grafana.
            </li>
        </ul>
        <div class="changes-section">Улучшения в бэк-офисе</div>
        <ul>
            <li>
                Все страницы бэк-офиса научились отображать ошибки при выполнении запросов в отдельных блоках на текущей
                странице вместо того чтобы делать переход на страницу ошибок. Таким образом, во-первых, при ошибке
                запроса адрес страницы не меняется, и её можно попробовать обновить. Во-вторых, если на странице
                выполнялось несколько запросов, лишь часть из которых завершилась ошибками, остальное содержимое будет
                нормально отображаться.
            </li>
            <li>
                Если запросы к инфраструктурным бэкенд-сервисам не удаётся выполнить, то бэк-офис теперь отдаёт наружу
                код 502.
            </li>
            <li>
                В меню IP-адресов и блоков IP появилась внешняя ссылка с информацией об IP или начальном IP блока на
                сайте <ExternalLink url="https://ipinfo.io" text="ipinfo.io" />.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.10.4</span>
            <span class="version-date">2023.11.24</span>
        </div>
        <div class="changes-section">Улучшения в Karma Gate</div>
        <ul>
            <li>
                Закрыта уязвимость: если путь запроса начинался со слэша, запрос адресовался не целевому сайту, а хосту,
                указанному после слэша.
            </li>
            <li>
                Гейт теперь получает репутацию IP-адреса на каждый входящий запрос. Это необходимо для честного решения
                проблем с многопоточностью в противовес ранее принятым мерам (ограничение количества одновременно
                обрабатываемых HTTP-запросов, настройки пула потоков).
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.10.3</span>
            <span class="version-date">2023.11.21</span>
        </div>
        <div class="changes-section">Расширение возможностей настройки правил в Karma Gate</div>
        <ul>
            <li>
                В правилах гейта появилась возможность обращаться к HTTP-контексту через поле context. Два поля
                касательно запроса - path и query - вынесены в отдельные поля для быстрого доступа. С помощью этого были
                настроены правила для перебрасывания на корень сайта при переходе по ссылкам вида
                http://[наш-домен]/http:/[другой-домен-и-левый-путь].
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.10.2</span>
            <span class="version-date">2023.10.31</span>
        </div>
        <div class="changes-section">Поддержка WebSocket'ов в Karma Gate</div>
        <ul>
            <li>
                В гейт добавлена поддержка веб-сокетов, метрики их текущей активности и попыток установки соединений.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.10.1</span>
            <span class="version-date">2023.10.14</span>
        </div>
        <div class="changes-section">Исправление ошибок</div>
        <ul>
            <li>Увеличен таймаут загрузки статистики репутации из базы данных.</li>
            <li>
                Убрана излишняя валидация IPN от PayProGlobal, переставшая работать после изменения содержимого их
                POST-запросов.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.10.0</span>
            <span class="version-date">2023.09.09</span>
        </div>
        <div class="changes-section">Переход на .NET 6.0</div>
        <ul>
            <li>Инфраструктурные сервисы переведены с netcoreapp3.1 на net6.0.</li>
        </ul>
        <div class="changes-section">Оптимизации</div>
        <ul>
            <li>Ускорено чтение ордеров и лицензий из базы данных в сервисе лицензирования.</li>
            <li>Ускорен анализ честности веб-сессий в сервисе телеметрии.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.9.5</span>
            <span class="version-date">2023.08.23</span>
        </div>
        <div class="changes-section">Оптимизация чтения статистики репутации</div>
        <ul>
            <li>Запросы статистики репутации были упрощены, что дало прирост скорости их выполнения в примерно 15%.</li>
            <li>
                Таймауты выполнения запросов от бэк-офиса к инфраструктурным сервисам, работающим с объёмными базами
                данных, были увеличены до 5 минут.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.9.4</span>
            <span class="version-date">2023.06.09</span>
        </div>
        <div class="changes-section">Улучшение отказоустойчивости Karma Gate</div>
        <ul>
            <li>
                Гейт начал более радикально реагировать на ошибки при запросах репутации IP-адресов, что позволяет ему
                без особых проблем переживать периоды полного отсутствия связи с сервисом репутации.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.9.3</span>
            <span class="version-date">2023.05.27</span>
        </div>
        <div class="changes-section">Кеширование в Karma Gate и дальнейшее улучшение его стабильности</div>
        <ul>
            <li>
                Гейт научился самостоятельно кешировать ответы на HTTP-запросы. Это значительно снижает нагрузку на
                защищаемый сайт и повышает стабильность его работы.
            </li>
            <li>
                Стабильность гейта дополнительно повышена путём ограничения количества одновременно обрабатываемых
                HTTP-запросов.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.9.2</span>
            <span class="version-date">2023.05.12</span>
        </div>
        <div class="changes-section">Дальнейшее улучшение стабильности Karma</div>
        <ul>
            <li>Стабильность гейта повышена с помощью настройки пула потоков.</li>
            <li>
                Исправлен недочёт: гейт больше не транслирует на защищаемый сайт заголовок Connection, полученный от
                клиента. TCP-сессии между ним и защищаемыми сайтами теперь нормально переиспользуются.
            </li>
        </ul>
        <div class="changes-section">Бэк-офис</div>
        <ul>
            <li>Отображение количества малоактивных/прямых сессий статистики репутации приведено к единому виду.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.9.1</span>
            <span class="version-date">2023.03.26</span>
        </div>
        <div class="changes-section">Улучшение стабильности и отказоустойчивости Karma Gate</div>
        <ul>
            <li>Улучшена потокобезопасность и отказоустойчивость гейта.</li>
            <li>Улучшено кеширование гейтом репутации блоков IP-адресов.</li>
            <li>
                Гейт получил возможность быстрого переключения в режим пропуска всех запросов - на случай аварий сервиса
                репутации.
            </li>
            <li>
                Исправлена обработка тестовых флагов репутации. Теперь использование этих флагов не портит репутацию
                других адресов из тех же блоков, из которого пришёл подобный запрос.
            </li>
            <li>
                Исправлена проблема кеширования флагов репутации IP-адресов в сервисе репутации. Теперь флаги,
                полученные из тэгов IP (такие как принадлежность к Google/Bing) вычисляются и выдаются исключительно на
                запрос репутации отдельного IP-адреса.
            </li>
            <li>
                Исправлена ошибка очерёдности применения правил гейта - они сортировались по алфавиту, а не в порядке,
                определённом в файле конфигурации.
            </li>
        </ul>
        <div class="changes-section">Приятные мелочи</div>
        <ul>
            <li>
                Бэк-офис научился отображать вердикт по веб-сессиям, либо факт того, что решение ещё не было принято.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.9.0</span>
            <span class="version-date">2023.03.23</span>
        </div>
        <div class="changes-section">Пересмотр принципов составления и оценки репутации</div>
        <ul>
            <li>
                Сервис репутации перестал оценивать пользовательскую телеметрию. Теперь он только принимает извне
                произвольные "события" касательно IP-адресов. Далее, на основании количества событий различного типа он
                вычисляет флаги репутации, описываемые в виде булевых выражений. Количество флагов репутации отныне
                никак не ограничивается.
            </li>
            <li>
                Сервис телеметрии теперь выступает в роли источника данных для сервиса репутации: он на регулярной
                основе отправляет события об IP-адресах, полученные в результате анализа имеющейся телеметрии.
            </li>
            <li>
                Гейт-приложение также получило большую гибкость настройки: правила, на основании которых принимаются
                решения при обработке HTTP-запросов, теперь описываются в виде булевых выражений.
            </li>
            <li>
                Флаги дополнительной информации об IP-адресах (такие как принадлежность к Google/Bing), ранее встроенные
                в гейт, теперь встроены в сервис репутации и отдаются в составе флагов репутации, что позволяет видеть
                их в том числе через бэк-офис.
            </li>
        </ul>
        <div class="changes-section">Улучшение защиты от директ-траффика</div>
        <ul>
            <li>При описании правил гейта появилась возможность проверки наличия заголовка Referer в запросе.</li>
            <li>
                В гейте прописаны редиректы на сайты-клоны в соответствии с требованиями, выведенными в рамках задачи
                <a href="https://tracker.recoverytoolbox.com:8443/issue/INFR-64" target="_blank">INFR-64</a>.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.8.0</span>
            <span class="version-date">2023.02.11</span>
        </div>
        <div class="changes-section">Защита от директ-траффика</div>
        <ul>
            <li>
                Сервис репутации научился агрегировать статистику веб-сессий по признаку наличия в них referer и
                выдавать на основании превышения порогов этой статистики особые флаги репутации.
            </li>
            <li>
                Соответственно гейт-приложение теперь способно активировать защиту по признаку превышения количества
                директ-сессий.
            </li>
            <li>Бэк-офис научился отображать статистику директ-сессий и осуществлять по ней фильтрацию.</li>
        </ul>
        <div class="changes-section">Исправление ошибок</div>
        <ul>
            <li>
                Сервис репутации некорректно проверял репутацию блоков ASN, из-за чего реакции, направленные против них,
                почти ни в каких случаях не работали.
            </li>
            <li>
                Исправлена ошибка в проверке коллизий диапазонов IP-адресов в сервисе репутации, из-за которой было
                возможно одновременно внести одни и те же IP-адреса и в чёрный, и в белый список.
            </li>
            <li>
                Исправлена ошибка в гейт-приложении, из-за которой заканчивались ошибкой запросы, в ответ на которые
                таргет-приложения отвечали редиректом с использованием относительного Location.
            </li>
            <li>Исправлено скачивание через гейт-приложение файлов размером более 2 Гб.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.14</span>
            <span class="version-date">2023.01.11</span>
        </div>
        <div class="changes-section">Улучшения в гейт-приложении</div>
        <ul>
            <li>
                Запросы от легитимных ботов Google/Bing определяются и пропускаются так же как запросы от адресов из
                белого списка.
            </li>
            <li>Исправлена ошибка чтения кеша, из-за которой выполнялись лишние запросы к сервису репутации.</li>
            <li>HTTPS-редиректы больше не отображаются в метриках.</li>
            <li>
                Ответы от защищаемых сайтов теперь отдаются с промежуточной буферизацией, что улучшило
                производительность и решило проблему скачивания больших файлов (более 256 Мб).
            </li>
            <li>
                Исправлена ошибка, из-за которой не работали запросы, в которых домен был указан не в нижнем регистре.
            </li>
            <li>Настроена реакция на запросы по признакам зловредности ASN и блока ASN.</li>
        </ul>
        <div class="changes-section">Улучшения в сервисе репутации</div>
        <ul>
            <li>Обновлены пороги опредения зловредных ASN и блоков ASN.</li>
            <li>Оптимизировано чтение истории статистики.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.13</span>
            <span class="version-date">2022.12.05</span>
        </div>
        <div class="changes-section">Автоблокировка зловредного траффика</div>
        <ul>
            <li>
                На прод-серверах включена автоматическая блокировка и перенаправление траффика по признаку зловредности
                статичного блока.
            </li>
            <li>
                Гейт-приложение теперь умеет применять дефолтные правила совместно (до или после) с правилами,
                описанными для конкретного набора таргет-сайтов. Это позволило обобщить реакцию на зловредный траффик
                между всеми прод-серверами.
            </li>
        </ul>
        <div class="changes-section">Внедрение метрик</div>
        <ul>
            <li>
                В гейт-приложении и в сервисе приёма IPN появились первые метрики. Формат экспорта метрик - Prometheus,
                за их визуализацию будет отвечать Grafana.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.12</span>
            <span class="version-date">2022.11.18</span>
        </div>
        <div class="changes-section">Подготовка к автоматической блокировке зловредного траффика</div>
        <ul>
            <li>
                Появился новый субъект статистики веб-сессий:
                <router-link to="/reputation/stats/static-blocks">статичные блоки</router-link>. Они не привязаны к ASN,
                а выводятся дроблением всего существующего диапазона IP-адресов на равные части. В данный момент размер
                маски для IPv4 - 24 бита, для IPv6 - 96 бит. Процесс работы с этими блоками практически полностью
                повторяет процесс с блоками ASN, за исключением того, что для них недоступен поиск по ASN, так как
                ASN'ам выдаются блоки произвольных размеров.
            </li>
            <li>
                На всех страницах статистики веб-сессий теперь отображается дополнительная иконка восклицательного знака
                напротив тех элементов, поведение которых посчитано сервисом репутации зловредным.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.11</span>
            <span class="version-date">2022.11.15</span>
        </div>
        <div class="changes-section">Статус включенности в списки</div>
        <ul>
            <li>
                На страницах статистики веб-сессий теперь отображаются специальные флаги, обозначающие, что данный
                ASN/блок ASN/IP-адрес полностью или частично покрыт чёрными/белыми списками. В отличие от ранее
                существовавших фильтров поиска (см. версию 1.7.7), этот расчёт целиком честный. Например, если часть
                блоков ASN включена в белый список, а часть - в чёрный, то данный ASN будет помечен одновременно как
                частично покрытый белыми списками и частично - чёрными. За рассчёт этих состояний отвечает прослойка в
                оперативной памяти сервиса, поэтому поиск по ним невозможен.
            </li>
            <li>
                Поиск репутационной статистики с учётом белых и чёрных списков, добавленный в версии 1.7.7, был удалён.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.10</span>
            <span class="version-date">2022.11.02</span>
        </div>
        <div class="changes-section">Флаги событий веб-сессий</div>
        <ul>
            <li>
                На странице списка веб-сессий теперь отображаются флаги, обозначающие, что в хронологии веб-сессии есть
                присутствуют ключевые события взаимодействия со страницей: скроллы, клики и использование элементов
                ввода.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.9</span>
            <span class="version-date">2022.10.27</span>
        </div>
        <div class="changes-section">Исправление/оптимизация чтения из баз данных</div>
        <ul>
            <li>
                Блокировки при чтении больших таблиц приводили к ошибкам 500 и отказу части фоновых процессов в ряде
                сервисов. Для ускорения чтения/записи и уменьшения вероятности конфликтных ситуаций чтение из баз в
                значительной части случаев теперь осуществляется без блокировки данных.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.8</span>
            <span class="version-date">2022.10.20</span>
        </div>
        <div class="changes-section">Фильтрация доступа ко внутренним инфраструктурным сервисам</div>
        <ul>
            <li>
                Инфраструктурные бэк-энд сервисы научились отказывать в доступе на основании IP-адреса клиента. Это
                важный шаг для обеспечения возможности их использования с других наших серверов.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.7</span>
            <span class="version-date">2022.10.17</span>
        </div>
        <div class="changes-section">Поиск репутационной статистики с учётом белых и чёрных списков</div>
        <ul>
            <li>
                Среди полей поиска по статистике веб-сессий появились галочки "Скрыть состоящие в белом/чёрном списке".
                В силу отсутствия некоторых операций в Transact SQL, а также фактических объёмов таблиц статистики, этот
                поиск имеет <i>неочевидные</i> ограничения:
                <ul>
                    <li>
                        При поиске статистики по ASN фильтрация осуществляется исключительно по идентификатору ASN в
                        чёрном и белом списках ASN. Соответственно если все блоки IP-адресов, которыми владеет ASN,
                        внесены в списки блоков IP-адресов, но сам ASN никуда не внесён, фильтрация не сработает.
                    </li>
                    <li>
                        При поиске статистики по блокам ASN фильтрация осуществляется по идентификатору ASN в чёрном и
                        белом списках ASN, а также по границам блока в чёрном и белом списке IP-адресов, но
                        исключительно по полному совпадению начального и конечного адресов. Соответственно если блок
                        IP-адресов внесён в списки через произвольные (не совпадающие полностью с существующим блоком
                        ASN) диапазоны адресов, фильтрация не сработает.
                    </li>
                    <li>
                        При поиске статистики по IP-адресам применяется фильтрация к ASN и блоку ASN, к которым
                        относится рассматриваемый IP-адрес, а также осуществляется проверка внесённости в списки одного
                        конкретного адреса (начальный и конечный адреса в списке совпадают). Таким образом, если
                        IP-адрес внесён в список вместе с другими адресами через произвольный диапазон адресов, не
                        совпадающий с существующим блоком ASN, фильтрация не сработает.
                    </li>
                </ul>
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.6</span>
            <span class="version-date">2022.09.27</span>
        </div>
        <div class="changes-section">Исправление ошибок</div>
        <ul>
            <li>Исправлена ошибка 400 при добавлении ASN в чёрный/белый список по ссылке из меню ASN.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.5</span>
            <span class="version-date">2022.09.19</span>
        </div>
        <div class="changes-section">Поиск веб-сессий по полю Referer</div>
        <ul>
            <li>Появилась возможность поиска по телеметрии веб-сессий по полю Referer.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.4</span>
            <span class="version-date">2022.09.05</span>
        </div>
        <div class="changes-section">Отчёты о свойствах веб-сессий</div>
        <ul>
            <li>
                В <router-link to="/misc/reporters">разделе отчётов</router-link> появилась возможность составления
                сводки по свойствам веб-сессий. В данный момент представлена информация по используемым браузерам,
                размерам экрана и операционным системам.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.3</span>
            <span class="version-date">2022.09.01</span>
        </div>
        <div class="changes-section">Разделение веб-сессий по сайтам</div>
        <ul>
            <li>
                Теперь веб-сессия, начатая на одном нашем сайте, а продолженная на другом, будет разделяться в
                телеметрии на две веб-сессии. При этом они будут иметь одинаковый внешний ID, так как он берётся из куки
                пользователя, которая привязана к сайту приёмника телеметрии, соответственно их не может быть более
                одной.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.2</span>
            <span class="version-date">2022.08.27</span>
        </div>
        <div class="changes-section">Новые поля в лицензировании</div>
        <ul>
            <li>У ордеров появилось поле количества купленных лицензий.</li>
            <li>У маппингов продуктов появилось поле стоимости одной лицензии.</li>
            <li>Оба новых поля используются при генерации отчётов об ордерах.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.1</span>
            <span class="version-date">2022.08.20</span>
        </div>
        <div class="changes-section">Отчёты через бэк-офис и рассылка по расписанию</div>
        <ul>
            <li>
                Отчёты сервисов лицензирования и телеметрии теперь будут рассылаться администраторам по расписанию. В
                данный момент расписания настроены на 3 число каждого месяца.
            </li>
            <li>
                Отчёты теперь также доступны для скачивания и генерации через
                <router-link to="/misc/reporters">новый раздел бэк-офиса</router-link>.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.7.0</span>
            <span class="version-date">2022.08.01</span>
        </div>
        <div class="changes-section">Генерация отчётов</div>
        <ul>
            <li>
                В сервисах лицензирования и телеметрии появились первые генераторы отчётов. Пока что их использование
                возможно исключительно напрямую через API, но это вопрос времени. В дальнейшем эти отчёты будут
                рассылаться автоматически с определённой периодичностью.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.6.0</span>
            <span class="version-date">2022.07.15</span>
        </div>
        <div class="changes-section">Защитный реверс-прокси</div>
        <ul>
            <li>
                В составе инфраструктуры появился реверс-прокси GateApp, позволяющий регулировать доступ к веб-сайтам на
                основании репутации IP-адресов в сервисе репутации, а также белых и чёрных списков.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.5.0</span>
            <span class="version-date">2022.07.02</span>
        </div>
        <div class="changes-section">Просмотр IP-информации, репутации, статистики веб-сессий, списки доступа</div>
        Это крупное обновление - предпоследний шаг на пути к построению системы <b>Karma</b> - защиты, основанной на
        пользовательской телеметрии.
        <ul>
            <li>
                Появился раздел <router-link to="/ipinfo">IP-информация</router-link>, предоставляющий информацию об ASN
                и IP-адресах, полученную от MaxMind.
            </li>
            <li>
                Новый большой раздел <router-link to="/reputation">Репутация</router-link> предоставляет интерфейсы с
                широкими возможностями просмотра/поиска репутации IP-адресов и статистики, рассчитанной сервисом
                репутации.
            </li>
            <li>
                Кроме того раздел репутации позволяет легко редактировать белые и чёрные списки IP-адресов и ASN. В
                данный момент это не имеет практического эффекта, но будущий Gate-сервис будет использовать в том числе
                эти списки при принятии решения о пропуске пользователей на наши сайты.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.4.3</span>
            <span class="version-date">2022.06.29</span>
        </div>
        <div class="changes-section">Исправление ошибок в приёме телеметрии</div>
        <ul>
            <li>Исправлен приём телеметрии без параметра Referer, ранее приводивший к ошибке 400.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.4.2</span>
            <span class="version-date">2022.06.08</span>
        </div>
        <div class="changes-section">Исправление ошибок в приёме телеметрии</div>
        <ul>
            <li>В клиентском скрипте телеметрии исправлена передача параметра Referer.</li>
            <li>Исправлено ограничение длины жизни сессии телеметрии.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.4.1</span>
            <span class="version-date">2022.04.22</span>
        </div>
        <div class="changes-section">Импортозамещение регистратора платежей</div>
        <ul>
            <li>
                Сервис обработки IPN научился работать с платформой Softline Ecommerce и её русскоязычно-брендированным
                вариантом AllSoft.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.4.0</span>
            <span class="version-date">2022.03.18</span>
        </div>
        <div class="changes-section">Сервис репутации</div>
        <ul>
            <li>
                В составе инфраструктуры появился IpInfoService, сынтегрированный с провайдером данных MaxMind,
                предоставляющий краткую информацию по IP-адресам.
            </li>
            <li>
                Вторая обновка - ReputationService, вычисляющий зловредную активность, исходя из пользовательской
                телеметрии. В дальнейшем этот сервис будет расширен, и на его основе будет построен механизм
                автоматизированной защиты от репутационных атак.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.3.1</span>
            <span class="version-date">2022.01.31</span>
        </div>
        <div class="changes-section">Исправление ошибок</div>
        <ul>
            <li>Исправлены обращения к базам данных из классов фоновой очистки баз данных.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.3.0</span>
            <span class="version-date">2021.12.30</span>
        </div>
        <div class="changes-section">Отправка уведомлений об истечении лицензий клиентам</div>
        <ul>
            <li>
                Сервис лицензирования теперь регулярно сканирует список лицензий и отправляет email-уведомления клиентам
                о скором истечении лицензий и о недавно истёкших лицензиях.
            </li>
            <li>
                Для поддержки отписки от уведомлений создан отдельный сервис. В дальнейшем его при необходимости можно
                будет применить для отписки от уведомлений любых других типов.
            </li>
        </ul>
        <div class="changes-section">Работа на тестовых средах</div>
        <ul>
            <li>BackOffice теперь отображает яркую плашку при работе на любых средах кроме Production.</li>
            <li>
                Сервис отправки уведомлений теперь добавляет имя среды в виде префикса к теме письма на любых средах
                кроме Production.
            </li>
        </ul>
        <div class="changes-section">Другие улучшения и исправления</div>
        <ul>
            <li>Исправлена ошибка конкурентного доступа к файлам отладочных дампов HTTP-запросов во всех сервисах.</li>
            <li>Внесён ряд изменений в чтение из баз данных с целью его ускорения.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.2.1</span>
            <span class="version-date">2021.11.18</span>
        </div>
        <div class="changes-section">Исправление в приёме веб-телеметрии</div>
        <ul>
            <li>
                Исправлен протокол и клиентский скрипт сервиса приёма телеметрии, чтобы корректно определялся сайт, на
                котором была начата сессия.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.2.0</span>
            <span class="version-date">2021.11.11</span>
        </div>
        <div class="changes-section">Веб-телеметрия</div>
        <ul>
            <li>Запущены сервисы для сбора телеметрии пользователей с веб-сайтов.</li>
            <li>
                Просмотр сессий веб-телеметрии доступен в
                <router-link to="/telemetry/web-sessions">новом разделе</router-link>.
            </li>
        </ul>
        <div class="changes-section">Улучшения в BackOffice</div>
        <ul>
            <li>Высота меню уменьшена.</li>
            <li>Внесены исправления для отображения горизонтальной прокрутки элементов, когда это требуется.</li>
            <li>Двухколоночные списки теперь занимают всю ширину страницы.</li>
            <li>Добавлен индивидуальный стиль скролл-баров для Chrome.</li>
            <li>Обновлены ключевые NPM-зависимости.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.1.4</span>
            <span class="version-date">2021.10.21</span>
        </div>
        <div class="changes-section">Исправления ошибок</div>
        <ul>
            <li>
                Сервис приёма IPN теперь отвечает OK на запрос о смене статуса ордера, даже если не нашёл
                соответствующего ордера в подсистеме лицензирования.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.1.3</span>
            <span class="version-date">2021.10.18</span>
        </div>
        <div class="changes-section">Исправления ошибок</div>
        <ul>
            <li>
                Сняты ограничения на дублирование ордеров в базе лицензирования, так как это мешало корректно
                обрабатывать IPN о купленных бандлах приложений в PPG. Способ поиска в базе ордера, соответствующего
                входящему IPN, был улучшен для уменьшения вероятности конфликтов.
            </li>
            <li>
                Убраны строгие проверки некоторых полей при приёме IPN, из-за чего не принимались IPN, указывающих на
                оплату заказа неизвестным ранее способом.
            </li>
        </ul>
        <div class="changes-section">Улучшения</div>
        <ul>
            <li>
                Поиск клиента в базе данных при приёме IPN теперь опирается не только на e-mail клиента, но так же на
                его внешний идентификатор у регистратора. Это позволит связывать друг с другом заказы клиента, даже если
                он сменил e-mail.
            </li>
            <li>Добавлен новый статус ордера - частичный рефанд.</li>
            <li>Добавлена обработка IPN типа OrderPartiallyRefunded.</li>
            <li>Добавлена обработка IPN типа OrderCustomerInformationChanged.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.1.2</span>
            <span class="version-date">2021.10.11</span>
        </div>
        <div class="changes-section">Улучшения</div>
        <ul>
            <li>
                Запрещено параллельное существование нескольких маппингов, совпадающих по регистратору и идентификатору
                продукта у регистратора, так как это делает невозможной обработку IPN.
            </li>
            <li>
                Несуществующий логотип домена продуктов (компании) больше не является препятствием при отправке писем c
                лицензиями.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.1.1</span>
            <span class="version-date">2021.09.27</span>
        </div>
        <div class="changes-section">Исправления ошибок</div>
        <ul>
            <li>
                Сервис приёма IPN некорректно проверял на бэкенде существование внешнего идентификатора покупателя,
                из-за чего получал ошибку 500. Однако это не приводило к сбою выдачи регистрационных кодов.
            </li>
            <li>Исправлена ссылка на раздел клиентов в подсказке к инструменту генерации регистрационных кодов.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.1.0</span>
            <span class="version-date">2021.09.11</span>
        </div>
        <div class="changes-section">Изменения в генерации лицензий</div>
        <ul>
            <li>Добавлена поддержка генерации ключей продления лицензий проприетарного формата SubLicense.</li>
            <li>
                Флаг "продление лицензии" был удалён у маппингов продуктов и добавлен в тип лицензии. Соответственно
                изменена логика выбора типа отправляемого клиенту письма (регистрация или продление).
            </li>
            <li>
                Также тип лицензии получил флаг "SubLicense-продление", который может быть активирован только если
                активирован флаг "продление лицензии". При установке этого флага указание срока действия и названия
                вшиваемого в регистрационный код типа лицензии лицензии (см. далее) становятся обязательными.
            </li>
            <li>
                Продукты получили поле "Пароль шифрования SubLicense". Если оно не установлено, ключи SubLicense
                шифруются стандартным паролем, заданным в конфигурационном файле сервиса.
            </li>
            <li>
                Тип лицензии получил новое поле "Название в регистрационном коде". Если задано, то оно вшивается в
                лицензию вместо имени типа лицензии. Для SubLicense-типов поле ограничено в длину 1 символом.
            </li>
            <li>
                При выписке лицензии помимо регистрационного ключа теперь отдельно сохраняются произвольные данные,
                зашитые внутрь ключа. Просмотреть эти данные можно на странице лицензии.
            </li>
        </ul>
        <div class="changes-section">Изменения в обработке IPN</div>
        <ul>
            <li>
                Добавлен новый способ определения соответствующих продуктам у регистратора внутренних продуктов и типов
                лицензий - парсинги продуктов. Они работают, исходя из названия продукта у регистратора, а не из его
                идентификатора. Название проверяется на соответствие регулярному выражению, и если совпадение есть, то
                продукт и тип лицензии вычисляются, исходя из групп регулярного выражения. У парсингов есть приоритет,
                который определяет очерёдность, в которой они проверяются при обработке IPN.
            </li>
            <li>
                Обработчик IPN теперь сначала ищет подходящий названию продукта парсинг, и лишь при его отсутствии
                откатывается к старой логике и ищет маппинг для идентификатора продукта.
            </li>
        </ul>
        <div class="changes-section">Вспомогательные инструменты лицензирования</div>
        <ul>
            <li>
                В лицензирование добавлен раздел "Инструменты", содержащий вспомогательные утилиты, не вносящие
                изменений в базу данных.
            </li>
            <li>
                Добавлен инструмент для проверки определения продукта и типа лицензии по информации о продукте у
                регистратора. Это пригодится для отладки обработки IPN, чтобы убедиться, что комбинация существующих
                парсингов и маппингов обеспечивает ожидаемое от системы лицензирования поведение.
            </li>
            <li>
                Добавлен инструмент для быстрой генерации регистрационных кодов продуктов. Инструмент в первую очередь
                полезен для разработчиков продуктов, чтобы иметь возможность протестировать работу продуктов на реальных
                лицензиях разных типов.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.0.5</span>
            <span class="version-date">2021.09.07</span>
        </div>
        <div class="changes-section">Исправления ошибок в обработке IPN</div>
        <ul>
            <li>
                Подпись IPN при валидации запроса теперь вычисляется, исходя из полностью неизменённых значений полей из
                POST-запроса IPN.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.0.4</span>
            <span class="version-date">2021.09.07</span>
        </div>
        <div class="changes-section">Исправления ошибок в обработке IPN</div>
        <ul>
            <li>
                Поля информации о клиенте, полученные из IPN, теперь обрезаются в случае превышении длины, допустимой в
                соответствующих колонках базы данных лицензирования.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.0.3</span>
            <span class="version-date">2021.06.16</span>
        </div>
        <div class="changes-section">Изменения в лицензировании</div>
        <ul>
            <li>
                При выписке лицензии дата истечения срока её действия теперь отсчитывается от момента покупки,
                указанного в ордере, а не от момента выписки.
            </li>
            <li>
                Дата покупки теперь вшивается в регистрационные коды лицензий в формате YYYYMMDD без указания времени
                вместо дефолтного преобразования даты и времени покупки в строку.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.0.2</span>
            <span class="version-date">2021.05.07</span>
        </div>
        <div class="changes-section">Изменения в лицензировании</div>
        <ul>
            <li>
                Ордеры, у которых одинаковые идентификаторы у регистратора, теперь допустимы, если у них разные
                продукты.
            </li>
            <li>Ссылка на техническую поддержку домена продуктов стала опциональным полем.</li>
            <li>
                У продукта появились опциональные индивидуальные поля сайта и страницы технической поддержки, которые
                используются в письмах вместо аналогичных полей домена продуктов, если они заданы.
            </li>
            <li>
                Если ссылка на страницу технической поддержки не задана ни у продукта, ни у его домена, в письмах
                используется ссылка на сайт продукта или домена.
            </li>
            <li>
                Размер поля ZIP у клиента увеличен с 10 до 20, чтобы вместить все возможные значения из старой
                MySql-базы.
            </li>
            <li>Имя клиента стало опциональным полем.</li>
        </ul>
        <div class="changes-section">Исправления ошибок в лицензировании</div>
        <ul>
            <li>Исправлена критическая ошибка, из-за которой сервис приёма IPN не мог запуститься.</li>
            <li>
                Исправлена ошибка, из-за которой сервис приёма IPN не обрататывал входящие запросы, если не мог удалить
                старые дампы IPN.
            </li>
        </ul>
        <div class="changes-section">Улучшения в BackOffice</div>
        <ul>
            <li>
                На страницах просмотра объектов идентификатор вынесен в заголовок страницы, чтобы не тратить место в
                списке свойств.
            </li>
            <li>
                На страницах редактирования объектов в заголовок страницы добавлен идентификатор объекта и ссылка на
                страницу его просмотра.
            </li>
            <li>Порядок и имена полей некоторых типов объектов были улучшены.</li>
            <li>
                На странице просмотра деталей домена продуктов появилась ссылка на поиск продуктов, входящих в домен.
            </li>
            <li>Упрощено отображение ненормальных статусов ордеров в списках ордеров и лицензий.</li>
            <li>
                При сохранении продуктов поля параметров провайдеров защит автоматически очищаются от пробелов, чтобы
                проще было вставлять скопированные из других мест значения.
            </li>
            <li>
                Ссылки и кнопки удаления пользователя скрываются или отключаются, если относятся к тому же пользователю,
                который залогинен.
            </li>
            <li>
                Добавлено пустое пространство внизу страниц, чтобы конец страницы был более очевиден, что особенно
                актуально для мобильных устройств.
            </li>
            <li>
                Элемент списка номеров страниц модифицирован, чтобы он занимал меньше места, к нему добавлено
                отображение общего числа объектов.
            </li>
            <li>Уменьшены отступы вокруг заголовков в мобильной вёрстке, чтобы занимать меньше места.</li>
        </ul>
        <div class="changes-section">Исправления ошибок в BackOffice</div>
        <ul>
            <li>Исправлена ошибка в поиске маппингов продуктов по регистратору.</li>
            <li>
                Добавлено исправление, чтобы автозаполнение в Chrome не портило внешний вид поисковых текстовых полей.
            </li>
            <li>
                Добавлено исправление, чтобы элемент списка номеров страниц полностью корректно отображался в Firefox.
            </li>
        </ul>

        <div class="version">
            <span class="version-number">1.0.1</span>
            <span class="version-date">2021.05.01</span>
        </div>
        <div class="changes-section">Улучшения в BackOffice</div>
        <ul>
            <li>При смене пароля пользователя удаляются все его токены на смену пароля.</li>
            <li>
                Поля для смены статуса и роли недоступны на странице редактирования пользователя, если пользователь
                редактирует там сам себя.
            </li>
            <li>В списке лицензий отображается особая надпись у лицензий, выписанных для тестовых ордеров.</li>
            <li>
                Страница входа автоматически переходит на главную страницу, если выясняет, что пользователь уже
                залогинен.
            </li>
            <li>Страница автоматически скроллится до самого верха при переходах по ссылкам.</li>
            <li>Добавлена страница истории версий.</li>
        </ul>

        <div class="version">
            <span class="version-number">1.0.0</span>
            <span class="version-date">2021.04.29</span>
        </div>
        Первая публичная версия BackOffice.
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import ExternalLink from '../../misc/ExternalLink.vue'

export default {
    name: 'ChangesPage',
    components: {
        ExternalLink,
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
}
</script>

<style scoped>
.version {
    margin: 20px 0px;
    color: #ccc;
}

.version > * {
    display: inline-block;
    padding: 4px 8px;
}

.version-number {
    background-color: #111;
    border-radius: 6px 0px 0px 6px;
}

.version-date {
    background-color: #444;
    border-radius: 0px 6px 6px 0px;
}

.changes-section {
    margin: 15px 0px;
    color: #ccc;
    font-weight: bold;
}
</style>