<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание типа лицензий</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/license-types/${licenseTypeId}/view`">типа лицензий #{{ licenseTypeId }}</router-link>
        </h1>

        <RequestWrapper
            v-bind:data="licenseType"
            v-bind:errorText="licenseTypeErrorText"
            v-bind:errorCode="licenseTypeErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="license-type-name"
                            labelText="Название:"
                            v-bind:errorText="nameValidationError"
                            required
                            v-model="licenseType.name" />
                        <NumberField
                            id="license-type-expiration-period"
                            v-bind:min="0"
                            v-bind:max="9999"
                            v-bind:required="isSubLicenseSet"
                            labelText="Срок действия (дней):"
                            placeholderText="Не ограничен"
                            v-bind:errorText="expirationPeriodValidationError"
                            v-model="licenseType.expirationPeriodDays" />
                        <TextField
                            id="license-type-regcode-name"
                            v-bind:maxlength="isSubLicenseSet ? 1 : 50"
                            v-bind:required="isSubLicenseSet"
                            labelText="Название в регистрационном коде:"
                            placeholderText="Использовать основное название"
                            v-bind:errorText="regCodeNameValidationError"
                            v-model="licenseType.regCodeName" />
                    </WrappedContent>
                    <WrappedContent>
                        <CheckBox
                            id="license-type-prolongation"
                            labelText="Продление лицензии"
                            v-model="licenseType.isProlongation" />
                        <CheckBox
                            id="license-type-sublicense"
                            v-bind:enabled="licenseType.isProlongation"
                            labelText="SubLicense-продление лицензии"
                            v-model="licenseType.isSubLicense"
                            :key="isSubLicenseKey" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/license-types/${licenseTypeId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import TextField from '../../../inputs/TextField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import CheckBox from '../../../inputs/CheckBox.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'LicenseTypeEditPage',
    components: {
        WrappedContent,
        TextField,
        NumberField,
        CheckBox,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            licenseTypeId: this.$route.params.id,
            nameValidationError: '',
            expirationPeriodValidationError: '',
            regCodeNameValidationError: '',
            isSubLicenseKey: 0,
            licenseType: undefined,
            licenseTypeErrorText: undefined,
            licenseTypeErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.licenseTypeId == undefined
        },
        isSubLicenseSet: function() {
            return this.licenseType.isProlongation && this.licenseType.isSubLicense
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.licenseType = {
                name: '',
                expirationPeriodDays: undefined,
                regCodeName: undefined,
                isProlongation: false,
                isSubLicense: false,
            }
        } else {
            getAuthorizedRequest(`/api/licensing/license-types/${this.licenseTypeId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.licenseType = {
                                    name: data.name,
                                    expirationPeriodDays: data.expirationPeriodDays,
                                    regCodeName: data.regCodeName,
                                    isProlongation: data.isProlongation,
                                    isSubLicense: data.isSubLicense,
                                }
                            })
                            break
                        default:
                            this.licenseTypeErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.licenseTypeErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.licenseType.name = this.licenseType.name.trim()
            if (!this.licenseType.expirationPeriodDays) this.licenseType.expirationPeriodDays = undefined
            this.licenseType.regCodeName = this.licenseType.regCodeName && this.licenseType.regCodeName.trim()
            this.licenseType.isSubLicense = this.licenseType.isSubLicense && this.licenseType.isProlongation
            this.isSubLicenseKey++ // иначе состояние чекбокса не обновится

            var validationOk = true

            if (!this.licenseType.name) {
                this.nameValidationError = 'Введите название'
                validationOk = false
            } else {
                this.nameValidationError = ''
            }

            if (this.isSubLicenseSet) {
                if (!this.licenseType.expirationPeriodDays) {
                    this.expirationPeriodValidationError = 'Введите срок действия'
                    validationOk = false
                } else {
                    this.expirationPeriodValidationError = ''
                }

                if (!this.licenseType.regCodeName) {
                    this.regCodeNameValidationError = 'Введите название'
                    validationOk = false
                } else if (this.licenseType.regCodeName.length > 1) {
                    this.regCodeNameValidationError = 'Введите один символ'
                    validationOk = false
                } else {
                    this.regCodeNameValidationError = ''
                }
            } else {
                this.expirationPeriodValidationError = ''
                this.regCodeNameValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/license-types', this.licenseType)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data =>
                                    this.$router.push(`/licensing/license-types/${data.licenseTypeId}/view`)
                                )
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/license-types/${this.licenseTypeId}`, this.licenseType)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные типа лицензии сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },
    },
}
</script>