<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание регистратора</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/registrators/${registratorId}/view`"
                >регистратора #{{ registratorId }}</router-link
            >
        </h1>

        <RequestWrapper
            v-bind:data="registrator"
            v-bind:errorText="registratorErrorText"
            v-bind:errorCode="registratorErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="registrator-name"
                            labelText="Название:"
                            v-bind:errorText="nameValidationError"
                            required
                            v-model="registrator.name" />
                        <TextField
                            id="registrator-site"
                            labelText="Сайт:"
                            placeholderText="Не установлен"
                            v-bind:errorText="siteValidationError"
                            v-model="registrator.site" />
                        <TextField
                            id="registrator-email"
                            labelText="E-mail для связи:"
                            placeholderText="Не установлен"
                            v-bind:errorText="emailValidationError"
                            v-model="registrator.email" />
                    </WrappedContent>
                    <Note>
                        Плейсхолдеры, доступные для шаблона ссылок:
                        <ul>
                            <li>
                                <b v-pre>{{ lang }}</b> - двухбуквенный код языка (ru, en, zh, fr и т.д.)
                            </li>
                            <li>
                                <b v-pre>{{ prop: RegistratorProductId }}</b> - идентификатор продукта у регистратора
                            </li>
                            <li>
                                <b v-pre>{{ prop: Registrator.[поле] }}</b> - прямое обращение к полям объекта
                                регистратора
                            </li>
                            <li>
                                <b v-pre>{{ prop: Product.[поле] }}</b> - прямое обращение к полям объекта продукта
                            </li>
                        </ul>
                    </Note>
                    <TextField
                        id="registrator-product-checkout-link-template"
                        labelText="Шаблон ссылки на заказ продукта (в данный момент не используется):"
                        placeholderText="Не установлена"
                        v-model="registrator.productCheckoutLinkTemplate" />
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/registrators/${registratorId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import TextField from '../../../inputs/TextField.vue'
import Note from '../../../misc/Note.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'
import { validateEmail, validateUrl } from '../../../../common.js'

export default {
    name: 'RegistratorEditPage',
    components: {
        WrappedContent,
        TextField,
        Note,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            registratorId: this.$route.params.id,
            nameValidationError: '',
            siteValidationError: '',
            emailValidationError: '',
            registrator: undefined,
            registratorErrorText: undefined,
            registratorErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.registratorId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.registrator = {
                name: '',
                site: '',
                email: '',
                productCheckoutLinkTemplate: '',
            }
        } else {
            getAuthorizedRequest(`/api/licensing/registrators/${this.registratorId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.registrator = {
                                    name: data.name,
                                    site: data.site,
                                    email: data.email,
                                    productCheckoutLinkTemplate: data.productCheckoutLinkTemplate,
                                }
                            })
                            break
                        default:
                            this.registratorErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.registratorErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.registrator.name = this.registrator.name.trim()
            this.registrator.site = (this.registrator.site && this.registrator.site.trim()) || undefined
            this.registrator.email = (this.registrator.email && this.registrator.email.trim()) || undefined
            this.registrator.productCheckoutLinkTemplate =
                (this.registrator.productCheckoutLinkTemplate && this.registrator.productCheckoutLinkTemplate.trim()) ||
                undefined

            var validationOk = true

            if (!this.registrator.name) {
                this.nameValidationError = 'Введите название'
                validationOk = false
            } else {
                this.nameValidationError = ''
            }

            if (this.registrator.site) {
                this.siteValidationError = validateUrl(this.registrator.site)
                if (this.siteValidationError) {
                    validationOk = false
                }
            }

            if (this.registrator.email) {
                this.emailValidationError = validateEmail(this.registrator.email)
                if (this.emailValidationError) {
                    validationOk = false
                }
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/registrators', this.registrator)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data =>
                                    this.$router.push(`/licensing/registrators/${data.registratorId}/view`)
                                )
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/registrators/${this.registratorId}`, this.registrator)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные регистратора сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },
    },
}
</script>