import { render, staticRenderFns } from "./EmailsList.vue?vue&type=template&id=6be1adc4&scoped=true"
import script from "./EmailsList.vue?vue&type=script&lang=js"
export * from "./EmailsList.vue?vue&type=script&lang=js"
import style0 from "./EmailsList.vue?vue&type=style&index=0&id=6be1adc4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be1adc4",
  null
  
)

export default component.exports