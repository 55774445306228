<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание маппинга продуктов</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/product-mappings/${productMappingId}/view`"
                >маппинга продуктов #{{ productMappingId }}</router-link
            >
        </h1>

        <RequestWrapper
            v-bind:data="productMapping"
            v-bind:errorText="productMappingErrorText"
            v-bind:errorCode="productMappingErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <SearchField
                            id="product-mapping-registrator"
                            labelText="Регистратор:"
                            required
                            v-bind:errorText="registratorValidationError"
                            textKey="name"
                            valueKey="registratorId"
                            v-bind:getInfoUrl="`/api/licensing/registrators/${productMapping.registratorId}`"
                            searchUrl="/api/licensing/registrators/search"
                            searchKey="namePartial"
                            v-model="productMapping.registratorId" />
                        <TextField
                            id="product-mapping-registrator-product-id"
                            labelText="Идентификатор у регистратора:"
                            placeholderText="Отсутствует"
                            v-model="productMapping.registratorProductId" />
                        <SearchField
                            id="product-mapping-product"
                            labelText="Продукт:"
                            required
                            v-bind:errorText="productValidationError"
                            textKey="name"
                            valueKey="productId"
                            v-bind:getInfoUrl="`/api/licensing/products/${productMapping.productId}`"
                            searchUrl="/api/licensing/products/search"
                            searchKey="namePartial"
                            v-model="productMapping.productId" />
                        <SearchField
                            id="product-mapping-license-type"
                            labelText="Тип лицензии:"
                            required
                            v-bind:errorText="licenseTypeValidationError"
                            textKey="name"
                            valueKey="licenseTypeId"
                            v-bind:getInfoUrl="`/api/licensing/license-types/${productMapping.licenseTypeId}`"
                            searchUrl="/api/licensing/license-types/search"
                            searchKey="namePartial"
                            v-model="productMapping.licenseTypeId" />
                        <SearchField
                            id="product-mapping-renewal-license-type"
                            labelText="Тип лицензии продления:"
                            textKey="name"
                            valueKey="licenseTypeId"
                            v-bind:getInfoUrl="`/api/licensing/license-types/${productMapping.renewalLicenseTypeId}`"
                            searchUrl="/api/licensing/license-types/search"
                            searchKey="namePartial"
                            v-model="productMapping.renewalLicenseTypeId" />
                        <NumberField
                            id="product-mapping-license-price"
                            labelText="Стоимость одной лицензии:"
                            required
                            min="0"
                            step="0.01"
                            v-bind:errorText="licensePriceValidationError"
                            v-model="productMapping.licensePrice" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/product-mappings/${productMappingId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import TextField from '../../../inputs/TextField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import SearchField from '../../../inputs/SearchField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductMappingEditPage',
    components: {
        WrappedContent,
        TextField,
        NumberField,
        SearchField,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productMappingId: this.$route.params.id,
            registratorValidationError: '',
            productValidationError: '',
            licenseTypeValidationError: '',
            licensePriceValidationError: '',
            productMapping: undefined,
            productMappingErrorText: undefined,
            productMappingErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.productMappingId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.productMapping = {
                registratorId: undefined,
                registratorProductId: '',
                productId: undefined,
                licenseTypeId: undefined,
                renewalLicenseTypeId: undefined,
                licensePrice: undefined,
            }
        } else {
            getAuthorizedRequest(`/api/licensing/product-mappings/${this.productMappingId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.productMapping = {
                                    registratorId: data.registrator.registratorId,
                                    registratorProductId: data.registratorProductId,
                                    productId: data.product.productId,
                                    licenseTypeId: data.licenseType.licenseTypeId,
                                    renewalLicenseTypeId: data.renewalLicenseType?.licenseTypeId,
                                    licensePrice: data.licensePrice,
                                }
                            })
                            break
                        default:
                            this.productMappingErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.productMappingErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''

            var validationOk = true

            if (!this.productMapping.registratorId) {
                this.registratorValidationError = 'Укажите регистратора'
                validationOk = false
            } else {
                this.registratorValidationError = ''
            }

            if (!this.productMapping.productId) {
                this.productValidationError = 'Укажите продукт'
                validationOk = false
            } else {
                this.productValidationError = ''
            }

            if (!this.productMapping.licenseTypeId) {
                this.licenseTypeValidationError = 'Укажите тип лицензии'
                validationOk = false
            } else {
                this.licenseTypeValidationError = ''
            }

            if (!this.productMapping.licensePrice) {
                this.licensePriceValidationError = 'Введите стоимость'
                validationOk = false
            } else {
                this.licensePriceValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/product-mappings', this.productMapping)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data =>
                                    this.$router.push(`/licensing/product-mappings/${data.productMappingId}/view`)
                                )
                                break
                            case 409:
                                this.saveRequestFailure = 'Аналогичный маппинг уже существует'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/product-mappings/${this.productMappingId}`, this.productMapping)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные маппинга продуктов сохранены'
                                break
                            case 409:
                                this.saveRequestFailure = 'Аналогичный маппинг уже существует'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },
    },
}
</script>