<template>
    <div>
        <h1 id="title-source">Детали маппинга продуктов #{{ productMappingId }}</h1>

        <RequestWrapper
            v-bind:data="productMapping"
            v-bind:errorText="productMappingErrorText"
            v-bind:errorCode="productMappingErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Регистратор">
                        <router-link
                            v-bind:to="`/licensing/registrators/${productMapping.registrator.registratorId}/view`">
                            {{ productMapping.registrator.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Идентификатор у регистратора">
                        {{ productMapping.registratorProductId || '&lt; Отсутствует &gt;' }}
                    </ItemDetail>
                    <ItemDetail name="Продукт">
                        <router-link v-bind:to="`/licensing/products/${productMapping.product.productId}/view`">
                            {{ productMapping.product.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Тип лицензии">
                        <router-link
                            v-bind:to="`/licensing/license-types/${productMapping.licenseType.licenseTypeId}/view`">
                            {{ productMapping.licenseType.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Тип лицензии продления">
                        <router-link
                            v-if="productMapping.renewalLicenseType"
                            v-bind:to="`/licensing/license-types/${productMapping.renewalLicenseType.licenseTypeId}/view`">
                            {{ productMapping.renewalLicenseType.name }}
                        </router-link>
                        <span v-else> Не установлен </span>
                    </ItemDetail>
                    <ItemDetail name="Стоимость одной лицензии">
                        {{ productMapping.licensePrice }}
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-support">
                    <Button
                        text="Редактировать"
                        v-on:click="$router.push(`/licensing/product-mappings/${productMappingId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/product-mappings/${productMappingId}/delete`)" />
                </ButtonsBlock>

                <div class="access-level-admin">
                    <h2>Поиск</h2>
                    <ul>
                        <li>
                            <router-link
                                v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/product-mappings/${productMappingId}`">
                                Записи аудита на этот маппинг продуктов
                            </router-link>
                        </li>
                    </ul>
                </div>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductMappingViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productMappingId: this.$route.params.id,
            productMapping: undefined,
            productMappingErrorText: undefined,
            productMappingErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/product-mappings/${this.productMappingId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.productMapping = data))
                        break
                    default:
                        this.productMappingErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.productMappingErrorText = error))
    },
}
</script>