import { render, staticRenderFns } from "./ButtonLink.vue?vue&type=template&id=f509aff8&scoped=true"
import script from "./ButtonLink.vue?vue&type=script&lang=js"
export * from "./ButtonLink.vue?vue&type=script&lang=js"
import style0 from "./ButtonLink.vue?vue&type=style&index=0&id=f509aff8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f509aff8",
  null
  
)

export default component.exports