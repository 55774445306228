<template>
    <div>
        <h1 id="title-source">Детали продукта #{{ productId }}</h1>

        <RequestWrapper v-bind:data="product" v-bind:errorText="productErrorText" v-bind:errorCode="productErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Название">
                        {{ product.name }}
                    </ItemDetail>
                    <ItemDetail name="Домен">
                        <router-link
                            v-bind:to="`/licensing/product-domains/${product.productDomain.productDomainId}/view`">
                            {{ product.productDomain.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Ссылка на скачивание">
                        <ExternalLink v-bind:url="product.downloadLink" />
                    </ItemDetail>
                    <ItemDetail name="Шаблон ссылки на покупку продления">
                        {{ product.renewalLinkTemplate || 'Не установлен' }}
                    </ItemDetail>
                    <ItemDetail name="E-mail тех. поддержки">
                        <EmailAddressLink v-bind:address="product.supportEmail" />
                    </ItemDetail>
                    <ItemDetail name="Сайт">
                        <ExternalLink v-bind:url="product.site" />
                    </ItemDetail>
                    <ItemDetail name="Страница тех. поддержки">
                        <ExternalLink v-bind:url="product.supportLink" />
                    </ItemDetail>
                    <ItemDetail name="Провайдер защиты">
                        {{ product.protectionProvider }}
                    </ItemDetail>
                    <ItemDetail name="Ключ в реестре">
                        {{ product.registryKey }}
                    </ItemDetail>
                    <ItemDetail name="Переопределение срока первой лицензии">
                        {{ product.licenseExpirationPeriodDaysOverride || 'Не переопределён' }}
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-support">
                    <Button text="Редактировать" v-on:click="$router.push(`/licensing/products/${productId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/products/${productId}/delete`)" />
                </ButtonsBlock>

                <h2>Поиск</h2>
                <ul>
                    <li>
                        <router-link v-bind:to="`/licensing/orders?mode=search&productId=${productId}`">
                            Ордеры на этот продукт
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/licenses?mode=search&productId=${productId}`">
                            Лицензии, выписанные на ордеры этого продукта
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/product-mappings?mode=search&productId=${productId}`">
                            Маппинги на этот продукт
                        </router-link>
                    </li>
                    <li class="access-level-admin">
                        <router-link
                            v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/products/${productId}`">
                            Записи аудита на этот продукт
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        ExternalLink,
        EmailAddressLink,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productId: this.$route.params.id,
            product: undefined,
            productErrorText: undefined,
            productErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/products/${this.productId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.product = data))
                        break
                    default:
                        this.productErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.productErrorText = error))
    },
}
</script>